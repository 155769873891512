import { useEffect } from 'react';
import './App.css';
// import { Chatbot, theme } from './Chatbot';
// import { ThemeProvider } from 'styled-components';
import { dimEffect, randomColorEffect } from './effect';

function App() {
  useEffect(async () => {
    const brand_name = 'Bee Agency JSC';
    const chars = [];
    for (let i = 0; i < brand_name.length; i++) {
      const char = brand_name[i];
      chars.push(char);
    }

    try {
      while (true) {
        dimEffect('brand_name', chars, 10, 20000);
        await new Promise((resolve, reject) => { setTimeout(() => { resolve(); }, 21000); });

        randomColorEffect('brand_name', chars, 6, 2000);
        // await timeMs(2000);
        await new Promise((resolve, reject) => { setTimeout(() => { resolve(); }, 2000); });

        randomColorEffect('brand_name', chars, 1000, 4000);
        await new Promise((resolve, reject) => { setTimeout(() => { resolve(); }, 4000); });
        // await timeMs(2000);

        randomColorEffect('brand_name', chars, 6, 2000);
        await new Promise((resolve, reject) => { setTimeout(() => { resolve(); }, 2000); });
        // await timeMs(2000);
      }
    } catch (error) {
      console.table(error);
    }
  }, [])

  return (
    <div className="center-div" style={{ color: 'white' }}>
      <div className="content">
        <div id="brand_name" style={{ color: 'white', fontSize: '128pt' }}>
          Bee Agency JSC
        </div>
        <div style={{ color: 'white', fontSize: '28pt', marginTop: '40px' }}>
          Bee Agency is a company focus mainly on Technology, Real Estate and Finance Agency.
          We are listening and nurturing the dream to bring peace and happiness to humanity and society.
        </div>
      </div>
    </div>
    // <div className="App" style={{ color: 'white' }}>
    //   {/* <ThemeProvider theme={theme}>
    //     <Chatbot />;
    // </ThemeProvider> */}
    //   Bee Agency JSC
    // </div>
  );
}

export default App;
