import { convertHex } from '../util/color';

export const dimEffect = (html_id, chars, freq, periodInMs) => {
    const htmlEle = document.getElementById(html_id);
    let color = '#FFF';
    let opacity = 1;
    const interval = setInterval(() => {
        opacity -= 0.01;
        if (opacity <= 0.15) {
            opacity = 1;
        }
        let content = `<span style="color: ${convertHex(color, opacity)}">${chars.join('')}</span>`;
        htmlEle.innerHTML = content;
    }, 1000 / freq);

    if (periodInMs > 0) {
        setTimeout(() => {
            clearInterval(interval);
        }, periodInMs);
    }
};

export const randomColorEffect = (html_id, chars, freq, periodInMs) => {
    const htmlEle = document.getElementById(html_id);
    let text = chars.join('');

    const interval = setInterval(() => {
        const colors = new Array(chars.length).fill(1).map(e => {
            return Math.floor(Math.random() * 16777215).toString(16);
        });
        const spans = colors.map((color, char_i) => {
            return text.charAt(char_i).fontcolor(`#${color}`);
        });
        htmlEle.innerHTML = spans.join('');
    }, 1000 / freq);

    if (periodInMs > 0) {
        setTimeout(() => {
            clearInterval(interval);
        }, periodInMs);
    }
};